.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}

a {
  text-decoration: none;
}

.awesome-icon {
  color: #183153;
  font-size: 24px;
}

.awesome-icon:hover {
  color: #c9d5e1;;
}


.logo-icon {
  color: inherit;
}

.logo-icon:hover {
  color: #fbc025;;
}

.blurDialog {
  backdrop-filter: blur(2px);
}


.muiTextAreaStatic {
  border-radius: 0rem;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.muiTextArea {
  border-radius: 0.5rem;
  font-size: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition: box-shadow 0.5s ease-in-out;
}

.muiTextArea:focus {
  outline: none;
  box-shadow: 0 0 1rem 0.3rem rgba(60, 137, 224, 0.54);
  transition: box-shadow 0.3s ease-in-out;
}

.blueScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 5px;
}

.blueScroll::-webkit-scrollbar {
  width: 3px;
  background-color: #F5F5F5;
}

.blueScroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-image: -webkit-gradient(linear,
  left bottom,
  left top,
  color-stop(0.44, rgb(122, 153, 217)),
  color-stop(0.72, rgb(73, 125, 189)),
  color-stop(0.86, rgb(28, 58, 148)));
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.pointer {
  cursor: pointer;
}

.gray_icon {
  color: #272727;
}
.yellow_icon {
  color: #fbc025;
}

.awesomepointer {
  cursor: pointer;
  color: #272727;
}
.awesomepointer:hover {
  color: #fbc025;;
  transition-duration: 0.5s;
}


/* Hide the uncaught error */
body > iframe { display: none }