.threeD * {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/*hide scroll bar*/
.threeD {
  overflow: hidden;
}